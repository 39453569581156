<template>
    <div>
        <Nav />

        <Tittle :type="'general'">
            <h1 class="fadeInUp"><span></span>FASLEARN POLICY</h1>
        </Tittle>

        <div class="container margin_60_35">
			<div class="row">
				<div class="col-lg-9">
					<div class="bloglist singlepost">
						<!-- <p><img alt="" class="img-fluid" src="http://via.placeholder.com/800x400/ccc/fff/blog-single.jpg"></p> -->
						<h1>PRIVACY POLICY</h1>
						<div class="postmeta">
							<ul>
								<li><a href="#"><i class="icon_folder-alt"></i> Collections</a></li>
								<li><a href="#"><i class="icon_clock_alt"></i> 06/06/2021</a></li>
								<li><a href="#"><i class="icon_pencil-edit"></i> Admin</a></li>
								<li><a href="#"><i class="icon_comment_alt"></i></a></li>
							</ul>
						</div>
						<!-- /post meta -->
						<div class="post-content">
							<div class="dropcaps">
								<p>
                                    This Policy applies to information that FASLEARN collects through the FASLEARN Site when you interact with FASLEARN, with Members, with other users, and generally with the FASLEARN Site.
                                    Your information is received and controlled by FASLEARN according to this Privacy Policy when you sign up for a FASLEARN account or otherwise use the FASLEARN Site.
                                    FASLEARN is solely and independently responsible for its own privacy practices. No Member is responsible for FASLEARN’s privacy practices.
                                    If you enroll in a course offered by a Member through the FASLEARN Site, information about you and your activity in the course is received and controlled also by that Member as described in this Privacy Policy.
                                    Each Member is solely and independently responsible for its own privacy practices. No Member is responsible for another Member’s privacy practices. 
                                </p>
                                <p>
                                    Also, FASLEARN is not responsible for any Member’s privacy practices.
                                    If you do not accept the terms of this Privacy Policy, then please do not access, browse or register for the FASLEARN Site or enroll in any courses. If you choose not to provide certain information required to provide you with various products and services offered on the FASLEARN Site, then you may not be able to establish a user account or obtain those products or services.
                                    INFORMATION THAT FASLEARN COLLECTS FROM YOU (INCLUDING PERSONAL INFORMATION)
                                </p>
                                <p>
                                    FASLEARN directly collects information when you: sign up for an FASLEARN user account and create an FASLEARN user profile; enroll and participate in online courses; purchase a product or service (for example, Verified Certificates); send email messages to FASLEARN (including messages to learner support); participate in user surveys; and participate in public forums, webinars, and other interactive experiences on the FASLEARN Site.
                                    FASLEARN also indirectly collects usage information about your visit to the FASLEARN Site, as explained in the section below titled Cookies and Tracking Technologies.
                                </p>
                                <p>
                                    FASLEARN does not itself collect or store financial account numbers or identification issued by a governmental entity, employer, or other authority. Instead, if you choose to make a purchase on the FASLEARN Site, for example a Verified Certificate, you will be directed to FASLEARN’s third-party payment processor and FASLEARN’s third-party ID verification service provider. If you do not wish to submit the required authentication or payment information, then you will not be able to obtain a certificate via the FASLEARN Site. FASLEARN requires your Personal Information only in limited circumstances when you: sign up for an FASLEARN user account (name, username, email address); purchase a Verified Certificate (so that FASLEARN’s third-party vendor can authenticate your identity) or other product or service (so that FASLEARN’s third-party vendor can process payment); participate in remote proctoring or other course facilitation processes (so that FASLEARN’s third-party vendor can monitor and assess the integrity of your course activity); or Earn a Verified Certificate (the certificate will have your name on it).
                                    To the extent that FASLEARN associates the information that FASLEARN collects directly or indirectly with an individual (for example, you), the association is based on Personal Information in your account profile. You may voluntarily choose to share additional Personal Information on the FASLEARN Site, for example:
                                    in your FASLEARN account profile (and you may display a limited or full profile to other FASLEARN Site users); in applying for financial assistance; or
                                    when participating in a course, forum, webinar, or other interactive experience on the FASLEARN Site (noting that in some cases, this information is shared with a third party that provides tools or other assistance for the FASLEARN Site or a course).
                                    FASLEARN encourages you to use discretion before voluntarily sharing additional Personal Information on the FASLEARN Site.
                                    (If you later choose to delete your FASLEARN account, deletion of your Personal Information will be subject to the process and limits outlined below.) Members do not receive your Personal Information unless and until you enroll in a course. If you enroll in a course, the Member that offers the course will receive Personal Information from your FASLEARN account profile plus the information that FASLEARN collects about your activity and performance in the course in which you enrolled.
                                </p>
                                
                                <h2>   COOKIES AND TRACKING TECHNOLOGIES ON THE FASLEARN SITE </h2>
                                <p>
                                    Like many companies, FASLEARN uses Cookies and other common Tracking Technologies on the FASLEARN Site and in email communications to help FASLEARN better understand your use and needs of the FASLEARN Site, current and future FASLEARN-related products and services, and how FASLEARN and Members can improve. For example: based on your cookie settings, FASLEARN tracks information indicating, among other things, which pages of the FASLEARN Site were visited, the order in which they were visited, when they were visited, and which hyperlinks and other user interface controls were used;
                                    FASLEARN may log the IP address, operating system, and browser software (including mobile use and device type when you use FASLEARN native mobile apps) you used when accessing the FASLEARN Site, and FASLEARN may be able to determine from an IP address your Internet Service Provider and the geographic location of your point of connectivity; and FASLEARN may track and collect information from emails that FASLEARN sends to you, for example whether you opened the email or clicked on any links from the email.
                                    FASLEARN also partners with third parties to help us track and understand your activity on the FASLEARN Site, how you interact with FASLEARN’s social media, and how you find or may be referred to the FASLEARN Site. Individual Members may separately use Tracking Technologies within their courses and email communications to understand your activity within a course and how you find or may be referred to a course on the FASLEARN Site.
                                    FASLEARN and Members use Cookies and other Tracking Technologies for the following purposes: When necessary for system administration, security, and integrity to ensure the FASLEARN Site works properly for you. Without these technologies, the FASLEARN Site may not function correctly; to enable analytics, performance and functionality, to help FASLEARN and Members gather information about how visitors use the FASLEARN Site and monitor FASLEARN Site performance, and to enhance your experience by recognizing and remembering whether you have visited the FASLEARN Site before and may have any personal preferences; and To support marketing by enabling FASLEARN and Members to deliver content relevant to your interests on the FASLEARN Site and third-party sites based on how you interact with FASLEARN or Member advertisements or content. FASLEARN and Members use “first-party” cookies, which means cookies served by FASLEARN or an individual Member, and also “third-party” cookies, which means cookies served by third parties that help FASLEARN and Members track this information.
                                    You have some options for managing Cookies and other Tracking Technologies. These include: Modifying your browser settings: You should be able to control how and whether your web browser accepts Cookies by adjusting its privacy and security settings. The “help” feature of the menu bar on most browsers will tell you how to stop accepting new Cookies, how to receive notification of new Cookies, and how to disable existing Cookies. If you reject FASLEARN ‘s Cookies, many functions and conveniences of the FASLEARN Site may not work properly. Opting-out of certain third-party tracking and advertising: Some of the third parties used by FASLEARN or a Member for Cookies and Tracking Technologies offer the ability to opt-out from their tracking by following processes described on their websites. Neither FASLEARN nor any Member is responsible for the effectiveness of any third-party opt-out options.
                                    HOW YOUR INFORMATION IS USED
                                </p>
                                <p>
                                    FASLEARN and Members use information, including Personal Information, to carry out the following purposes: Operate and improve the FASLEARN Site – This includes creating and improving features that support the FASLEARN community and enable FASLEARN Site usage at scale. This also includes personalizing the FASLEARN Site, so your learning experience is tailored to your interests and needs.
                                </p>
                                <p>
                                    Create, administer, provide, and teach courses – This includes creating and improving the delivery of massive open online courses and programs on FASLEARN. This also includes personalizing courses, so your learning experience is tailored to your interests and needs, and assessing your performance and awarding certificates.
                                    Offer and improve products and services – This includes enabling you to navigate the FASLEARN Site, to enroll and participate in courses and programs on the FASLEARN Site, to learn effectively in such courses and programs, and to purchase or obtain products and services on the FASLEARN Site such as Verified Certificates.
                                    Develop and maintain the security and performance of the FASLEARN Site – This includes tracking FASLEARN Site outages and creating software solutions, detecting violations of the Honor Code and Terms of Service, and monitoring uses,  issues, and potential misuses of the FASLEARN Site. Communicate with you – This includes answering your course and platform questions, notifying you of course and FASLEARN Site maintenance and updates, marketing to you about course offerings, programs, news, and related products and services of FASLEARN or FASLEARN affiliates, and as permitted under applicable law, sending you communications about products or services of selected business partners that may be of interest to you. Support scientific research including, for example, in the areas of cognitive science and education – This includes collaborating to enable and conduct research about how learners’ access and master course materials online, with the goal of improving course outcomes.
                                    Track FASLEARN Site usage against goals and mission – This includes performing analytics to evaluate access to and performance in courses and course-related products and services and to report aggregate usage information (not Personal Information) to business partners and external audiences.
                                    Exercise, enforce, and comply with legal rights and obligations – This includes responding to subpoenas, court orders, or other legal process; and investigating, preventing, or taking action regarding illegal activities, suspected fraud, security or technical issues, or to protect the rights, property, or safety of FASLEARN, Members, or others, and as otherwise required by applicable law.
                                    FASLEARN or a Member may also seek your consent for additional uses of information, including Personal Information, and will use it only for the purpose described to you. All use of Personal Information is subject to applicable law.
                                </p>
                                    <h2> HOW YOUR INFORMATION IS SHARED </h2>
                                
                                <p>
                                    FASLEARN and Members share information, including Personal Information, with third parties for the following purposes: With service providers, contractors, and other third parties that help carry out the uses described above – FASLEARN requires third parties to: (1) abide by this Privacy Policy and applicable law; (2) handle Personal Information in a confidential manner and maintain adequate security; and (3) use Personal Information only as needed to fulfill the relevant purpose(s). In some cases, the FASLEARN Site and individual courses are integrated with third-party services or contain links to websites published by third parties, including other content providers as well as service providers. These third parties are responsible for their own privacy practices, so you should pay attention anytime you are redirected to a third-party website and be sure to review its privacy policy.
                                    With other learners in courses that you take and with other visitors to the FASLEARN Site, to create an interactive learning environment, support class participation, and share course information – This includes sharing comments, coursework, or other information or content that you submit to a portion of the FASLEARN Site designed for viewing by other class members or for public communication. This also includes providing opportunities for you to communicate with other users who may have similar interests or educational goals, for instance, recommending specific study partners or connecting potential student mentees and mentors. In such cases, FASLEARN and each Member that offers a course in which you enrolled may use all information collected about you to determine who might be interested in communicating with you, but will only provide others your username (for clarity, no disclosure of your real name or email address).
                                </p>
                                <p>
                                    With employers, government programs, institutions, or other enterprises that sponsor your enrollment in a course for training or other educational purposes – If an entity pays for or otherwise sponsors your Verified Certificate or course participation, FASLEARN will share information with the entity as needed to confirm your enrollment, participation, progress, and completion status in that course.
                                    With affiliates of FASLEARN or a Member, or with successors in the event of a merger, acquisition, or reorganization, for their use consistent with this Privacy Policy.
                                    FASLEARN or a Member may also seek your consent for additional disclosures of information, including Personal Information, and will share it only as described to you. In addition, FASLEARN or a Member may share with the public and with third parties, including but not limited to researchers and business partners, information and Personal Information that is de-identified or aggregated in a manner that does not personally identify you.
                                </p>
                                    <h2> HOW TO MANAGE YOUR PERSONAL INFORMATION </h2>
                                <p>
                                    You can manage your Personal Information through learner features on the FASLEARN Site and requests to the FASLEARN learner support team. If your request involves information controlled by a Member, FASLEARN learner support will notify and coordinate with the appropriate Member to address your request. FASLEARN will provide support to the extent required by applicable law (for example, learners in the European Union) and more broadly, when possible, as a courtesy in our sole discretion. FASLEARN will notify you in the event that FASLEARN is unable to meet a request that is not legally required. Similarly, Members will only be obligated to comply with requests to the extent legally required, but may choose to comply with other requests in their sole discretion.
                                    Access and correct your Personal Information Much of your Personal Information is available in your FASLEARN account. Your name, email address, and other identifiable profile information are editable through the profile settings in your FASLEARN account except as noted below. Your account dashboard lists all current and archived courses in which you have enrolled and includes links to any Verified Certificates you may have earned. The dashboard also contains copies of your answers and other participation and performance in courses. Please contact FASLEARN learner support to access and update this or other information. If your request involves information controlled by a Member, FASLEARN learner support will notify the appropriate Member of your request.
                                </p>
                                    <h3> Exceptions: </h3>
                                <p>
                                    Usernames cannot ever be changed. This is an FASLEARN system restriction. Because usernames identify you in courses and elsewhere on the FASLEARN Site, FASLEARN encourages you to use discretion in choosing your username. If you want to protect your identity, do not use your real name or a name identifiable to you as your username.
                                    FASLEARN does not track or index every time or every place on the FASLEARN Site when or where a learner may volunteer Personal Information, so neither FASLEARN nor Members will be able to help you locate or manage all such instances. FASLEARN encourages you to use discretion before volunteering Personal Information on the FASLEARN Site.
                                    Restrict or object to the processing of Personal Information
                                </p>
                                <p>
                                    If you want to manage emails and other communications to you, you may update your preferences in your FASLEARN account dashboard; follow the steps described in links at the bottom of email messages, or contact FASLEARN learner support. You may object to, or request that FASLEARN or a Member stop, its use of your Personal Information for other purposes by contacting the FASLEARN learner support team. If your request involves information controlled by a Member, FASLEARN learner support will notify the appropriate Member of your request. Please note that if you choose to restrict FASLEARN’s or a Member’s ability to process Personal Information, and the processing is otherwise required to provide you with various services and products offered on the FASLEARN Site, you may not be able to establish an FASLEARN user account or enroll for a course, and FASLEARN or a Member may not be able to provide you with those services or products.
                                </p>
                                    <h3> Delete account and Personal Information </h3>
                                <p>
                                    To request deletion of your FASLEARN account and Personal Information, you should click the button labeled “Delete my account” in your FASLEARN account settings. Because deletion on the FASLEARN system is a permanent action and cannot be reversed, FASLEARN may ask you to complete a process that aims to confirm your authority to manage the FASLEARN account affected by your request.
                                </p>
                                    <h3> With respect to your FASLEARN account, FASLEARN will permanently: </h3>
                                <p>
                                    deactivate your FASLEARN account, remove the active profile, retire your username, and remove you from FASLEARN email lists. With respect to your Personal Information, FASLEARN will permanently delete your FASLEARN account profile Personal Information from the FASLEARN Site.
                                    As noted above, to the extent that FASLEARN associates the information that FASLEARN collects directly or indirectly with an individual (for example, you), the association is based on Personal Information in your FASLEARN account profile. By erasing the Personal Information in your FASLEARN account profile, the remaining information about your activity on the FASLEARN Site will no longer be associated with you, except as noted below.
                                </p>
                                <p>
                                    These changes will be applied to data stores that are used for operation of the FASLEARN Site including course administration by Members. If you enrolled in any courses on the FASLEARN Site, the FASLEARN learner support team will share your request with the Members that offered those courses.
                                </p>
                                    <h3> Exceptions: </h3>
                                <p>
                                    Deletion will not apply to historical activity logs or archives unless and until these logs and data naturally “age-off” the FASLEARN system.
                                    FASLEARN does not track or index every time or every place on the FASLEARN Site when or where a learner may volunteer Personal Information, so neither FASLEARN nor Members will be able to help you locate or manage all such instances. FASLEARN encourages you to use discretion before voluntarily sharing your Personal Information on the FASLEARN Site. FASLEARN will archive course data (in a manner and to the extent permitted under applicable law) to serve its mission to enable scientific research on cognitive science and education. These archives are used to produce encrypted research data packages for Members, and Personal Information may not be deleted from research data packages retained by Members.
                                </p>
                                <p>
                                    FASLEARN and each applicable Member cannot always delete records of past interactions and transactions. For example, records relating to previous purchases on the FASLEARN Site must be retained for financial reporting, audit, and compliance reasons. FASLEARN and each Member will retain and use Personal Information as necessary to comply with its legal obligations, resolve disputes, enforce its agreements, and as otherwise permitted by applicable law.
                                </p>
                                    <h2> Data retention </h2>
                                
                                <p>
                                    FASLEARN will retain your Personal Information for as long as your account is active or as needed to provide you with services; to maintain a record of your transactions for financial reporting, audit, and compliance purposes; and to comply with FASLEARN’s legal obligations, resolve disputes, enforce its agreements, and as otherwise permitted by applicable law. If you enroll in a Member’s course, such Member will also retain your Personal Information for as long as needed to provide you with services; to maintain a record of your transactions for financial reporting, audit, and compliance purposes; and to comply with its legal obligations, resolve disputes, enforce its agreements, and as otherwise permitted by applicable law. Upon your request that FASLEARN deactivate your account and delete your information, FASLEARN will follow the process described above, including without limitation archiving your course data (in a manner and to the extent permitted under applicable law) to serve its mission to enable scientific research on cognitive science and education. These archives will be used to produce encrypted research data packages for Members, and each such Member may also keep and use course data for scientific research.
                                </p>
                                    <h2>    STORAGE & SECURITY </h2>
                                <p>
                                    FASLEARN stores information, including Personal Information, on its own servers and also on servers of companies that FASLEARN hires to provide services. Each Member also stores information, including Personal Information, on its own servers and/or on servers of companies that the Member hires to provide services. In each case, information may be stored in Nigeria and in other countries where FASLEARN or a Member operates, and in countries where FASLEARN and each Member’s respective service providers operate. If you are in the European Union, Switzerland, or other regions with laws governing data collection and use, you acknowledge that FASLEARN and each Member may transfer, process and store your personal information in Nigeria and other countries, the privacy laws of which may be considered less strict than those of your region.
                                </p>
                                <p>
                                    FASLEARN controls its own copy of information collected through the FASLEARN Site and has an information security program designed to protect information in its possession or control. This is done through a variety of privacy and security policies, processes, and procedures. FASLEARN uses administrative, physical, and technical safeguards that reasonably and appropriately protect the confidentiality, integrity, and availability of the information that it collects, receives, stores, or transmits. Nonetheless, no method of transmission over the Internet, or method of electronic storage, is 100% secure; and therefore, FASLEARN cannot guarantee its absolute security. While FASLEARN works hard to ensure the integrity and security of its network and systems, FASLEARN cannot guarantee that its security measures will prevent “hackers” or other unauthorized persons from illegally accessing or obtaining information.
                                </p>
                                <p>
                                    If FASLEARN learns of a security breach involving its copy of your Personal Information, FASLEARN may attempt to notify you electronically so that you may take appropriate protective steps. By using the FASLEARN Site or providing Personal Information to FASLEARN, you agree that FASLEARN can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the FASLEARN Site. If a security systems breach occurs, FASLEARN may post a notice on the FASLEARN homepage or elsewhere on the FASLEARN Site and may send an email to you at the email address associated with your FASLEARN account. Depending on where you are located, you may have a legal right to receive notice of a security breach, involving your Personal Information, in writing. If a Member learns of a security breach involving that Member’s copy of your Personal Information, the Member may attempt to notify you electronically so that you may take appropriate protective steps. By enrolling in a member’s course on the FASLEARN Site or providing Personal Information to the Member, you agree that the Member can communicate with you electronically regarding security, privacy, and administrative issues relating to your course enrollment and participation. If a security systems breach occurs, the affected Member may post a notice on the FASLEARN site and/or send an email to you at the email address associated with your enrollment in the Member’s course on the FASLEARN Site. Depending on where you are located, you may have a legal right to receive notice of a security breach, involving your Personal Information, in writing.
                                </p>   
                                    <h2> PRIVACY POLICY UPDATES </h2>
                                <p>
                                    This Privacy Policy will be reviewed and updated from time to time. When changes are made, the Privacy Policy will be labeled as "Revised (date)," indicating that you should review the new terms, which will be effective immediately upon posting on this page, with an updated effective date. By accessing the FASLEARN Site after any changes have been made, you accept the modified Privacy Policy and any changes contained therein. In case you miss the notification referenced above, be sure to return to this page periodically to ensure familiarity with the most current version of this Privacy Policy.
                                </p>
                                    <h2> CONTACT INFORMATION </h2>
                                <p>
                                    If you have privacy concerns, have disclosed data you would prefer to keep private, or would like to access the Personal Information that FASLEARN maintains about you, please contact us at info@faslearn.com. If your request involves a Member, FASLEARN  will notify the appropriate Member of your request.
                                
                                </p>
                                    <h2> GLOSSARY </h2>
                                <p>
                                    Cookies are unique identifiers usually in the form of small data files placed on your device that send certain information about your activity on the FASLEARN Site or in an email communication back to FASLEARN or the authorized third party that served the cookie. FASLEARN or third parties may also use Flash cookies.
                                    FASLEARN Site consists of all content and pages located within the web domain and all FASLEARN mobile applications. Member is each educational institution or other leading global institution or individual that provides courses through the FASLEARN Site.
                                    Personal Information is information that specifically identifies you or that, when combined with other information, could be used to identify you.
                                    Tracking Technologies are web beacons, clear gifs, pixels and similar technologies that are also unique identifiers used to track your online activity but are not stored on your device.

                                    Verified Certificate is a virtual certificate that shows that you have successfully completed your FASLEARN course or program and verified your identity using your webcam and your acceptable form of photo ID.

                                </p>
							</div>
                        </div>
						<!-- /post -->
					</div>
					<!-- /single-post -->

					<hr>
				</div>
				<!-- /col -->
				<!-- /aside -->
			</div>
			<!-- /row -->
		</div>
		<!-- /container -->
        <Footer />
    </div>
</template>

<script>
    import Nav from '@/components/site/Nav.vue'
    import Tittle from '@/components/site/Tittle.vue'
    import Footer from '@/components/site/Footer.vue'
    export default {
        name: 'Blogpost',
        components: {
            Nav,
            Tittle,
            Footer
        },
        mounted(){
            let js = document.createElement('script')
            js.setAttribute('src', 'js/main.js')
            document.head.appendChild(js)
            let jsc = document.createElement('script')
            jsc.setAttribute('src', 'js/common_scripts.js')
            document.head.appendChild(jsc)
        }
    }
</script>

<style>

</style>